<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ m.title + ` №${data.code_doc}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-status
        v-if="
          getAccess('documents.objectBuy.status', {
            users: [data.createdby_id],
          })
        "
        :items="statusItems"
        @click="changeStatus($event)"
        :disabled="canStatus"
      />
      <a-btn-edit v-if="data.status !== 2 && getAccess('documents.objectBuy.edit', { users: [data.createdby_id] })" @click="showEditDialog = true" :disabled="canEdit" />
      <a-btn-delete
        v-if="
          id &&
          data.status !== 2 &&
          getAccess('documents.objectBuy.delete', {
            users: [data.createdby_id],
          })
        "
        @click="removeDialogShow = true"
      />
    </template>

    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="data"
            :model="
              model.filter((el) => {
                return el.card == 1;
              })
            "
            :config="{ dense: true }"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view
            :value="Object.assign({}, data, balance)"
            :model="[
              ...model.filter((el) => {
                return el.card == 2;
              }),
              { name: 'credit', title: 'Итого к оплате', type: 'number' },
              { name: 'debit', title: 'Оплачено', type: 'number' },
              {
                name: 'saldo',
                value: balance.credit - balance.debit,
                title: 'Долг',
                type: 'number',
              },
            ]"
            :config="{ dense: true, hideNull: true }"
            @click="getBalance()"
          />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" ref="tabBlock" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" :href="`#tabBuy-${i}`" class="mr-3">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item id="tabBuy-0" data-name="files section">
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <v-fab-transition v-if="true">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('doc_all')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabBuy-1" data-name="cash section">
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-api
                  ref="tableCashOut"
                  :api="'/accounting/doc/order_withdrawal'"
                  :model="getModelList($store.getters['config/get'].models.cashOut)"
                  :searchable="false"
                  :useQuery="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    filters: {
                      operation_type: [17, 37, 42, 51],
                      parent_name: accDocName,
                      parent_id: id,
                    },
                    paramName: 'documentCashOut',
                  }"
                  @click="onClickCash($event)"
                >
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 2 &&
                        getAccess('documents.objectBuy.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="showCashSelectDialog = true">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-api>
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabBuy-2" data-name="loan section">
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <a-table-f-api
                  ref="tableLoan"
                  :api="'/accounting/doc/getting_loan'"
                  :model="getModelList($store.getters['config/get'].models.documentLoanGet)"
                  :searchable="false"
                  :useQuery="false"
                  :defaults="{
                    sort: { key: 'id', order: 'DESC' },
                    filters: {
                      operation_type: [34],
                      parent_name: accDocName,
                      parent_id: id,
                    },
                    paramName: 'documentLoanGet',
                  }"
                  @click="$refs.quickOpenDoc.openDocument(`AccountingDocGettingLoan`, $event.row.id, $event.row)"
                >
                  <template v-slot:top>
                    <v-fab-transition
                      v-if="
                        data.status == 1 &&
                        getAccess('documents.objectBuy.edit', {
                          users: [data.createdby_id],
                        })
                      "
                    >
                      <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createLoan()">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-fab-transition>
                  </template>
                </a-table-f-api>
              </v-card-text>
            </v-tab-item>
            <v-tab-item id="tabBuy-3">
              <comment-view :id="id || 0" :targetName="'objectBuy'" :needUpdate="needUpdateComments" />
            </v-tab-item>
            <v-tab-item id="tabBuy-4" data-name="acc section">
              <v-card-text :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <s-document-acc-record :id="id" :name="accDocName" :join="{ AccountingDocOrderWithdrawalModel: { parent_name: m.accDocName, parent_id: id } }" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <s-quickOpenDoc ref="quickOpenDoc" />
      <CashDialog
        ref="cashDialog"
        @updateData="
          $refs.tableCashOut[0].updateData();
          getBalance();
        "
      />
      <edit-dialog
        v-if="showEditDialog"
        v-model="showEditDialog"
        :id="id"
        :api="url"
        :m="m"
        :readonly="data.status == 2"
        :initType="data.type"
        :hasData="data.data_table && data.data_table.length > 0"
      />

      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <select-dialog v-model="showCashSelectDialog" v-if="showCashSelectDialog" :operations="operationsCash" @select="onSelectOperation($event)" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      <s-document-head
        ref="documentHead"
        v-model="showHeadDialog"
        v-if="showHeadDialog"
        :api="head.url"
        :m="head.m"
        :config="head.config"
        :title="head.title"
        :id="head.id"
        :initData="head.initData"
      />
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <change-value-dialog ref="changeValueDialog" />
    <a-loader v-if="loading" />
    <popup-menu v-model="popumMenuShow" :items="popupMenuList" :position="popupMenuPosition" @click="onClickMenu($event)" />
    <su-Data
      ref="suEdit"
      v-model="suEditShow"
      :api="api"
      :id="id"
      :activate="getAccess('suData')"
      :dataEdit="data"
      @show="suEditShow = true"
      v-if="getAccess('suData')"
    />
  </static-fullscreen-card>
</template>

<script>
//import { statusTask } from "@/config/modules/libs";
import cash from "@/components/s/document/cash";

import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, comments } from "@/components/mixings";
//import libsCash from "../libsCash";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, cash, comments],
  props: {
    idShow: Number,
  },
  components: {
    CashDialog: () => import("./../dialogs/cashDialog"),
    selectDialog: () => import("./../dialogs/cashOperationSelectDialog"),
    editDialog: () => import("./../dialogs/objectBuyDialog"),
  },
  data() {
    return {
      head: { id: 0, url: "", m: {}, title: null, config: {} },
      id: 0,
      idEdit: 0,
      idEditCash: 0,
      fieldsRO: [],

      accDocName: "AccountingDocBuildingBuyModel",
      needUpdateComments: 0,
      suEditShow: false,
      showCashSelectDialog: false,
      showEditDialog: false,
      showHeadDialog: false,
      showDialogRowEdit: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.documentObjectBuy,
      statuses: this.$store.getters["config/get"].models.documentObjectBuy.statuses,
      url: "/accounting/doc/building_buy",
      api: "/accounting/doc/building_buy",
      apiStatus: "/accounting/doc/building_buy",
      tab: 0,

      blockHeight: 256,
      debit: null,
      credit: null,
      statusCur: null,
    };
  },
  created() {
    this.loading = true;

    this.$root.title = "Договор на покупку объекта";
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    tabHeight() {
      let top = this.$refs.tabBlock?.$el?.offsetTop;
      let res = 300;
      if (top) res = this.$root.windowSize.innerHeight - top - 50;
      console.log(top, res);

      return res;
    },
    tabs() {
      let res = [
        { id: 1, title: "Документы", show: true },
        { id: 2, title: "Оплата", show: this.data.first_payment_type === 1 },
        { id: 3, title: "Кредит", show: this.data.operation_type == 36 },
        { id: 4, title: "Комментарии", show: true },
        { id: 5, title: "Проводки", show: this.getAccess("documents.objectBuy.accounting") },
      ];
      return res;
    },
    operationsCash() {
      let res = [...(this.m.operationsCash?.[this.data.operation_type] || [])].filter((f) => {
        return f != 51 || this.data.first_payment_type == 1;
      });
      //если оплата первоначального взноса инвестором, то отключаем операцию
      console.log("operationsCash", res, this.data.operation_type);
      return res;
    },
    balance() {
      return { debit: this.debit, credit: this.credit };
    },
    canStatus() {
      if (!this.data.seller_id) return true;
      return false;
    },
    canEdit() {
      return false;
    },
    model: {
      get() {
        let type = this.data.type || 1;
        let model = JSON.parse(JSON.stringify(this.m.form));
        model.forEach((el) => {
          if (el?.sourceList) {
            //если список вариантов находится во внешнем массиве, присоединяю его
            el.options = this.m?.[el.sourceList]
              ? this.m[el.sourceList].filter((list) => {
                  return !list?.hidden;
                })
              : [];
          }
        });
        this.modelReady = true;

        return model;
      },
    },
    statusItems() {
      if (!this.data.status) return [];
      let items = [];
      let res = [];
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next) {
        let arr = statusCur.next;
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let statusCur = this.statuses.find((el) => el.value == this.data.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    createLoan() {
      this.head.m = this.$store.getters["config/get"].models?.documentLoanGet || {};
      this.head.title = this.head.m?.title || "";
      this.head.id = 0;
      this.head.config = this.head.m?.config?.default || {};
      this.head.url = "/accounting/doc/getting_loan";
      this.head.initData = {
        object_id: this.data.object_id,
        parent_name: "AccountingDocBuildingBuyModel",
        parent_id: this.data.id,
      };
      this.showHeadDialog = true;
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;

      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    async getBalance() {
      console.log("getBalance");
      let debit = 0;
      let credit = 0;
      if (this.data.status !== 2) {
        credit += this.data.value;
      }
      //const acc = this.config.debit;
      let resp = await this.$axios.post("accounting/records/report-sql", {
        q: `
select sum(value_cr) 'value_cr', sum(value_db) 'value_db' from (
select id, credit_account 'account', value 'value_cr', null 'value_db'
from accounting_record
where credit_account like "60.3" and credit_subconto_value_2=${this.id}
union all
select id, debit_account 'account',	null 'value_cr', value 'value_db'
from accounting_record
where debit_account like "60.3" and debit_subconto_value_2=${this.id}
)  tt`,
      });

      if (resp.data.status == "ok") {
        resp.data.data.forEach((el) => {
          debit += el.value_db;
          credit += el.value_cr;
        });
      }
      this.credit = credit;
      this.debit = debit;
    },

    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", data);
      else this.$router.push({ name: "objectBuy" });
    },
    afterFetchData(r) {
      if (this.id) {
        this.getBalance();
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    async changeStatus(status) {
      let ok = false;
      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [`Вы действительно хотите ${status.value !== 2 ? "распровести" : "провести"} заказ?`],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;
      if (status.value == 2) {
        console.log("chrck credit balance");
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select sum(ac.value) as balance from accounting_doc_getting_loan gl
left join accounting_record ac on ac.debit_account='57.1' and ac.debit_subconto_value_1=gl.id
          where gl.parent_id=${this.id} and gl.parent_name="${this.accDocName}" and gl.deleted <> 1 and gl.status=2 and ac.debit_account='57.1'`,
        });
        if (resp.data.status == "ok") {
          let balance = resp.data.data[0]?.balance || 0;
          if (balance != this.data.value - (this.data.value_first || 0)) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Баланс кредита = " + balance.toLocaleString("ru-Ru"),
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки баланса кредита",
          });
          return;
        }
      }
      if (status.value !== 2) {
        let resp = await this.$axios.post("accounting/records/report-sql", {
          q: `select id from accounting_doc_order_withdrawal where parent_id=${this.id} and parent_name="${this.accDocName}" and deleted <> 1`,
        });
        if (resp.data.status == "ok") {
          if (resp.data.data.length > 0) {
            this.$root.$emit("show-info", {
              type: "error",
              text: "Не возможно сменить статус. Есть связанный документ оплаты",
            });
            return;
          }
        } else {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Не возможно сменить статус. Произошла ошибка проверки",
          });
          return;
        }
      }
      this.loading = true;
      let data = { id: this.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
      this.getBalance();
    },
    onSelectOperation(e) {
      if (!e.value) return;
      this.$refs.cashDialog.openDialog(e.id, 0, this.data, { operation_name: e.text });
    },

    onClickCash(d) {
      console.log();
      this.$refs.cashDialog.openDialog(d.row.operation_type, d.row.id, this.data);
    },
  },
};
</script>
